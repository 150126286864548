import React from "react"
import SectionHeader from "../../components/UI/elements/SectionHeader"
import Layout from "../../components/UI/layout"

const Assets = () => {
  return (
    <Layout>
      <SectionHeader headerText="Assets" />
    </Layout>
  )
}

export default Assets
